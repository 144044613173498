<template>
  <div v-frag>
    <v-container>
      <v-row>
        <!-- ÜGYFÉL ADATOK START -->
        <v-col cols="12" sm="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
              <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <template v-if="$store.state.authModule.user.profile.customerType === 'B2C' || $store.state.authModule.user.profile.customerType === 'EMPLOYEE'">
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="$store.state.authModule.user.profile.lastName"
                                  :label="$t('inputLabels.lastName')"
                                  filled
                                  readonly
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model="$store.state.authModule.user.profile.firstName"
                                  :label="$t('inputLabels.firstName')"
                                  filled
                                  readonly
                                  disabled>
                    </v-text-field>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="12" sm="8">
                    <v-text-field v-model="$store.state.authModule.user.profile.companyName"
                                  :label="$t('inputLabels.companyName')"
                                  filled
                                  readonly
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="$store.state.authModule.user.profile.businessForm.name"
                                  :label="$t('inputLabels.businessFormName')"
                                  filled
                                  readonly
                                  disabled>
                    </v-text-field>
                  </v-col>
                </template>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.profile.email"
                                :label="$t('inputLabels.email')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.profile.phone"
                                :label="$t('inputLabels.phone')"
                                prefix="+36-"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- ÜGYFÉL ADATOK END -->
        <!-- FIÓK ADATOK START -->
        <v-col cols="12" sm="6">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
              <span class="subtitle-1">{{ $t('accountInformation') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.profile.defaultCurrency.name"
                                :label="$t('accountCurrency')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.profile.defaultLanguage.name"
                                :label="$t('accountLanguage')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.profile.defaultPayment.name"
                                :label="$t('accountPayment')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- FIÓK ADATOK END -->
        <!-- PÉNZÜGYI ADATOK START -->
        <v-col cols="12">
          <v-card class="fill-height">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-account-cash</v-icon>
              <span class="subtitle-1">{{ $t('financialDatas') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.financialData.accountNumber"
                                :label="$t('inputLabels.accountNumber')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.financialData.taxNumber"
                                :label="$t('inputLabels.taxNumber')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.profile.defaultPayment.name"
                                :label="$t('accountPayment')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="$store.state.authModule.user.profile.defaultCurrency.name"
                                :label="$t('accountCurrency')"
                                filled
                                readonly
                                disabled>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- PÉNZÜGYI ADATOK END -->
      </v-row>
    </v-container>
    <!-- JELSZÓ CONTAINER START -->
    <change-password></change-password>
    <!-- JELSZÓ CONTAINER END -->
  </div>
</template>

<script>
import ChangePassword from "../auth/ForgotPassword/ChangePassword.vue";

export default {
  name: 'UserProfile',
  props: {
    //
  },
  components: {
    ChangePassword,
  },
  data() {
    return {
      userDataIsValid: false,
      passwordDialog: false,
      userData: {
        email: 'account@email.com',
        language: '',
        currency: '',
      },
      inputRules: {
        required: [v => !!v || this.$t('inputMessages.requiredField')],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      languageItems: [
        { text: 'Magyar', value: 'hu' },
      ],
      currencyItems: [
        { text: 'HUF', value: 'huf' },
        { text: 'EUR', value: 'eur' },
      ],
    };
  },
  methods: {
    closePasswordDialog() {
      this.passwordDialog = !this.passwordDialog;
    },
  },
};
</script>

<style scoped>

</style>
