<template>
  <div v-frag>
    <user-profile></user-profile>
  </div>
</template>

<script>
import UserProfile from '../../components/profile/UserProfile.vue';
import PrimaryContactInfo from '../../components/profile/PrimaryContactInfo.vue';

export default {
  name: 'InnerUserInformation',
  components: {
    UserProfile, PrimaryContactInfo,
  },
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
