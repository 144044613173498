<template>
  <v-card height="100%">
    <v-card-title>
      <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
      <span class="subtitle-1">{{ $t('primaryContactInformation') }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="px-6 pt-6">
      <v-text-field
        v-model="$store.state.profile.user.primaryContact.lastName"
        :aria-label="$t('lastName')"
        :label="$t('lastName')"
        filled
        prepend-inner-icon="mdi-account-outline"
        required
        disabled
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="$store.state.profile.user.primaryContact.firstName"
        :aria-label="$t('firstName')"
        :label="$t('firstName')"
        filled
        prepend-inner-icon="mdi-account-outline"
        required
        disabled
        class="mb-3"
      ></v-text-field>
      <v-text-field
        v-model="$store.state.profile.user.primaryContact.phoneNumber"
        :aria-label="$t('phoneNumber')"
        :label="$t('phoneNumber')"
        filled
        prepend-inner-icon="mdi-cellphone-android"
        required
        disabled
        class="mb-3"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PrimaryContactInfo',
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
